import React from 'react';

export function Button(
    props: React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
) {
    const { type = 'button' } = props;
    return (
        <button
            type={type}
            className="bg-button-blue text-white p-2 rounded-lg text-lg font-semibold w-full"
            {...props}
        />
    );
}

export function Input(
    props: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
) {
    return (
        <input
            className="bg-white border border-gray-300 p-2 rounded-lg w-full active:border-2 active:border-button-blue "
            {...props}
        />
    );
}

export function Page(
    props: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
) {
    return <div className="p-4 h-full w-full" {...props} />;
}
