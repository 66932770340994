import { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config.firbase.ts';
import { collections } from './types.query.ts';

export type CategoryResponse = {
    id: string;
    bgColor: string;
    description: string;
    fontColor: string;
    title: string;
    image: string;
};

async function getAllCategories(): Promise<CategoryResponse[]> {
    // Reference the collection you want to fetch
    const querySnapshot = await getDocs(collection(db, collections.categories));

    // Process the data and set it to state
    const dataList = querySnapshot.docs.map((category) => ({
        id: category.id,
        ...category.data(),
    }));
    return dataList as unknown as CategoryResponse[];
}

export async function getCategory(id: string): Promise<CategoryResponse> {
    const categoryDocRef = doc(db, collections.categories, id); // Replace 'categoryId' with the specific category ID
    const category = await getDoc(categoryDocRef);
    return {
        id: category.id,
        ...category.data(),
    } as CategoryResponse;
}

function useGetAllCategoriesQurey() {
    const [categories, setCategories] = useState<CategoryResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const handleQuery = async () => {
        setError(null);
        setLoading(true);
        try {
            const newCategories = await getAllCategories();
            setCategories(newCategories);
        } catch (e) {
            setError('Failed to get Data');
            console.error('Error fetching data: ', e);
        }
        setLoading(false);
    };
    useEffect(() => {
        handleQuery();
    }, []);

    return {
        categories,
        loading,
        error,
    };
}

export function useGetCategoryQurey(id?: string) {
    const [category, setCategory] = useState<CategoryResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const handleQuery = async () => {
        if (id === undefined) {
            setError('Failed to get Data');
            setLoading(false);
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const newCategory = await getCategory(id);
            setCategory(newCategory);
        } catch (e) {
            setError('Failed to get Data');
            console.error('Error fetching data: ', e);
        }
        setLoading(false);
    };

    useEffect(() => {
        handleQuery();
    }, []);

    return {
        category,
        loading,
        error,
    };
}

export default useGetAllCategoriesQurey;
