import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { Button, Input, Page } from '../../components/common.tsx';
import GoogleButton from '../../components/GoogleSignInButton.tsx';

function SignupPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        try {
            await signup(name, email, password);
            navigate(routeNames.nook);
        } catch (err) {
            setError('Failed to sign up');
        }
        setLoading(false);
    };

    return (
        <Page>
            <h1 className="text-center">Signup</h1>
            <p className="text-center">
                Already have an account?{' '}
                <Link
                    to={routeNames.login}
                    className="underline text-button-blue"
                >
                    Log in here
                </Link>
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col p-4 pt-2">
                {error && <p className="text-center text-red-600"> {error}</p>}
                <div className="pb-4">
                    <label htmlFor="name-input">First Name</label>
                    <Input
                        id="name-input"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="pb-4">
                    <label htmlFor="email-input">Email</label>
                    <Input
                        id="email-input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="pb-4">
                    <label htmlFor="password-input">Password</label>
                    <Input
                        id="password-input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="pb-2 w-full">
                    <GoogleButton>Signup with Google</GoogleButton>
                </div>
                <Button disabled={loading} type="submit">
                    {loading ? 'Loading...' : 'Signup'}
                </Button>
            </form>
        </Page>
    );
}

export default SignupPage;
