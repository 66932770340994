import {
    collection,
    doc,
    DocumentReference,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import { db } from '../firebase/config.firbase.ts';
import { collections } from './types.query.ts';

export type Content = {
    id: string;
    list: DocumentReference;
    title: string;
    image: string;
    description: string;
    src: string;
    mediaCategory: 'meditation' | 'motivation';
    mediaType: 'audio' | 'video';
    weight: number;
    mediaLength: number;
    interests: string[];
};

export async function getContentByList(id: string) {
    const listDocRef = doc(db, collections.lists, id);

    const contentQuery = query(
        collection(db, collections.content),
        where('list', '==', listDocRef)
    );

    const querySnapshot = await getDocs(contentQuery);
    return querySnapshot.docs.map((content) => ({
        id: content.id,
        ...content.data(),
    })) as Content[];
}

export async function getRandomContentFromInterest(
    interestIds: string[]
): Promise<Content[]> {
    try {
        const contentQuery = query(
            collection(db, collections.content),
            where('interests', 'array-contains-any', interestIds)
        );

        const querySnapshot = await getDocs(contentQuery);
        // Convert query snapshot to an array of documents
        return querySnapshot.docs.map((contentDoc) => ({
            id: contentDoc.id,
            ...contentDoc.data(),
        })) as Content[];
    } catch (error) {
        console.error('Error fetching random content:', error);
        throw error;
    }
}
