import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './navigation/Router.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { AudioProvider } from './contexts/AudioContext.tsx';

function App() {
    return (
        <AuthProvider>
            <AudioProvider>
                <RouterProvider router={router} />
            </AudioProvider>
        </AuthProvider>
    );
}

export default App;
