import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyDffUA8Xa_Yw6lcVtU_s6JN6nodCNLEGRU',
    authDomain: 'nook-1c49f.firebaseapp.com',
    projectId: 'nook-1c49f',
    storageBucket: 'nook-1c49f.appspot.com',
    messagingSenderId: '915500523596',
    appId: '1:915500523596:web:b0a10eca20fdb6650614e9',
    measurementId: 'G-SETGSVX31T',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

export default auth;
export const db = getFirestore(app);
export const provider = new GoogleAuthProvider();
