import React from 'react';
import {
    CustomerServiceOutlined,
    PlayCircleOutlined,
    SoundOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Content } from '../queries/content.query.ts';
import { shortenText } from '../utils/string.helpers.ts';
import { ListWithContent } from '../queries/lists.query.ts';

function selectIcon(type: 'audio' | 'video') {
    switch (type) {
        case 'audio':
            return <SoundOutlined />;
        case 'video':
            return <PlayCircleOutlined />;
        default:
            return <CustomerServiceOutlined />;
    }
}

export function ContentInfoItem(props: { content: Content; to: string }) {
    const { content, to } = props;
    return (
        <Link to={to}>
            <div className="rounded-lg h-20 w-full bg-gray-200 overflow-hidden">
                <img src={content.image} alt="" />
            </div>
            <label className="leading-3">{content.title}</label>
            <div className="flex flex-row">
                {selectIcon(content.mediaType)}
                <p className="font-semibold text-xs pl-1">
                    {content.mediaCategory}
                </p>
            </div>
            <p className="leading-4 text-sm">
                {shortenText(content.description || '', 30)}
            </p>
        </Link>
    );
}

export function ContentInfoList(props: {
    list: ListWithContent;
    onToHandler: (content: Content) => string;
}) {
    const { list, onToHandler } = props;

    return (
        <div>
            <h3>{list.title}</h3>
            <div className="grid grid-cols-2 gap-3">
                {list.content.map((item) => (
                    <ContentInfoItem
                        content={item}
                        key={item.id}
                        to={onToHandler(item)}
                    />
                ))}
            </div>
        </div>
    );
}
