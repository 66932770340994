import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import {
    Content,
    getRandomContentFromInterest,
} from '../../queries/content.query.ts';
import { Page } from '../../components/common.tsx';
import { capitalizeFirstLetter } from '../../utils/string.helpers.ts';
import { onToContent } from '../../utils/navigate.helpers.ts';

function NookContentTitle(props: { content: Content }) {
    const { content } = props;

    return (
        <Link to={onToContent(content)}>
            <div className="flex p-2 bg-gray-100 items-center rounded">
                <div className="h-16 w-16 overflow-hidden mr-2 rounded">
                    <img alt="" src={content.image} />
                </div>
                <div className="flex flex-col justify-center">
                    <p className="font-semibold text-xs text-gray-400">
                        {capitalizeFirstLetter(content.mediaCategory)}
                    </p>
                    <label className="text-md">{content.title}</label>
                    <p className="font-semibold text-xs text-gray-400">
                        {content.mediaLength} mins
                    </p>
                </div>
            </div>
        </Link>
    );
}

function NookPage() {
    const { userData } = useAuth();
    const [content, setContent] = useState<Content[]>();

    useEffect(() => {
        async function setup() {
            if (userData?.interests) {
                const newContent = await getRandomContentFromInterest(
                    userData?.interests
                );
                setContent(newContent);
            }
        }
        setup();
    }, []);

    return (
        <Page>
            <h2>Today&apos;s Meditations</h2>
            <div>
                {content?.map((item) => (
                    <div key={item.id} className="pb-2">
                        <NookContentTitle content={item} />
                    </div>
                ))}
            </div>
        </Page>
    );
}

export default NookPage;
