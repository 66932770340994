import React from 'react';

import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Page } from '../../components/common.tsx';
import { useGetListWithContent } from '../../queries/lists.query.ts';
import { ContentInfoList } from '../../components/ContentInfoItem.tsx';
import { onToContent } from '../../utils/navigate.helpers.ts';

function CategoryPage() {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const { lists } = useGetListWithContent(id);
    const category = JSON.parse(atob(searchParams.get('data')));
    const navigate = useNavigate();
    return (
        <Page>
            <div className="flex flex-row items-center">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <h1>{category?.title}</h1>
            </div>
            <p>{category?.description}</p>
            {lists.map((list) => (
                <div key={list.id} className="pb-4">
                    <ContentInfoList list={list} onToHandler={onToContent} />
                </div>
            ))}
        </Page>
    );
}

export default CategoryPage;
