/* eslint-disable import/prefer-default-export */
import {
    doc,
    DocumentReference,
    getDoc,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { collections } from './types.query.ts';
import auth, { db } from '../firebase/config.firbase.ts';

export type UserData = {
    id: string;
    isOnboarded: boolean;
    favourites: DocumentReference[];
    interests: string[];
};

export async function createUserData(uid: string): Promise<void> {
    return setDoc(doc(db, collections.users, uid), {
        isOnboarded: false,
        favouritedContent: [],
        interests: [],
    });
}

export async function setUserDataInterests(
    uid: string,
    interests: string[]
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        interests,
    });
}

export async function setUserDataHasOnboarded(uid: string): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        isOnboarded: true,
    });
}

export async function getUserData(): Promise<UserData | undefined> {
    if (auth.currentUser) {
        const userDocRef = doc(db, collections.users, auth.currentUser.uid);
        const userData = await getDoc(userDocRef);
        return {
            id: userData.id,
            ...userData.data(),
        } as UserData;
    }
    return undefined;
}
