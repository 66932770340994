import {
    collection,
    doc,
    DocumentReference,
    getDocs,
    query,
    where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase/config.firbase.ts';
import { collections } from './types.query.ts';
import { Content, getContentByList } from './content.query.ts';

/* eslint-disable import/prefer-default-export */
export type List = {
    id: string;
    title: string;
    category: DocumentReference;
    weight: number;
};

export type ListWithContent = List & {
    content: Content[];
};

export async function getListsByCategory(id: string): Promise<List[]> {
    // Step 1: Query all lists with a specific category reference
    const categoryDocRef = doc(db, collections.categories, id);

    const listsQuery = query(
        collection(db, collections.lists),
        where('category', '==', categoryDocRef)
    );

    const querySnapshot = await getDocs(listsQuery);
    // Step 2: Extract the list data from the query
    return querySnapshot.docs.map((list) => ({
        id: list.id,
        ...list.data(),
    })) as List[];
}

export async function getListsAndContent(
    id: string
): Promise<ListWithContent[]> {
    const lists = await getListsByCategory(id);
    const listsWithContent = lists as ListWithContent[];

    return Promise.all(lists.map((list) => getContentByList(list.id))).then(
        (responses) => {
            responses.forEach((response, index) => {
                listsWithContent[index].content = response;
            });
            return listsWithContent;
        }
    );
}

export function useGetListWithContent(id?: string) {
    const [lists, setLists] = useState<ListWithContent[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const handleQuery = async () => {
        if (id === undefined) {
            setError('Failed to get Data');
            setLoading(false);
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const newLists = await getListsAndContent(id);
            setLists(newLists);
        } catch (e) {
            setError('Failed to get Data');
            console.error('Error fetching data: ', e);
        }
        setLoading(false);
    };

    useEffect(() => {
        handleQuery();
    }, []);

    return {
        lists,
        loading,
        error,
    };
}
