import React, {
    createContext,
    useState,
    useContext,
    useRef,
    PropsWithChildren,
    useMemo,
} from 'react';
import { Content } from '../queries/content.query.ts';

// Define the types for the context
interface AudioContextType {
    isPlaying: boolean;
    playAudio: (content: Content) => void;
    pauseAudio: () => void;
    stopAudio: () => void;
    currentAudioUrl: string | null;
    content: Content;
}

// Create the context
const AudioContext = createContext<AudioContextType | undefined>(undefined);

// Custom hook to use the Audio context
export const useAudio = (): AudioContextType => {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error('useAudio must be used within an AudioProvider');
    }
    return context;
};

// Create the provider component
export function AudioProvider({ children }: PropsWithChildren) {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [content, setContent] = useState<Content>();

    // Play audio function
    const playAudio = (newContent: Content) => {
        // If the audio is already playing and the URL is the same, resume playback
        if (audioRef.current && newContent.src === currentAudioUrl) {
            audioRef.current.play();
            setIsPlaying(true);
            return;
        }

        // If a different audio is playing, stop it
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

        // Set the new audio URL and play it
        const newAudio = new Audio(newContent.src);
        audioRef.current = newAudio;
        setCurrentAudioUrl(newContent.src);
        setContent(newContent);
        newAudio.play();
        setIsPlaying(true);

        // Add event listener to update state when audio ends
        newAudio.addEventListener('ended', () => {
            setIsPlaying(false);
        });
    };

    // Pause audio function
    const pauseAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    // Stop audio function
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
            setCurrentAudioUrl(null);
        }
    };

    const values = useMemo(
        () => ({
            isPlaying,
            playAudio,
            pauseAudio,
            stopAudio,
            currentAudioUrl,
            content,
        }),
        [currentAudioUrl, isPlaying, playAudio, content]
    );

    return (
        <AudioContext.Provider value={values}>{children}</AudioContext.Provider>
    );
}
