import React, { ReactNode } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { HomeOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext.tsx';
import MusicPlayerBar from './MusicPlayerBar.tsx';
import { routeNames } from '../navigation/types.navigation.ts';

function PageLayout() {
    const { pathname } = useLocation();
    const { user } = useAuth();
    const menuItems: { label: string; path: routeNames; icon: ReactNode }[] = [
        {
            label: 'Nook',
            path: routeNames.nook,
            icon: <HomeOutlined className="text-3xl" />,
        },
        {
            label: 'Explore',
            icon: <SearchOutlined className="text-3xl" />,
            path: routeNames.explore,
        },
        {
            label: 'Account',
            path: routeNames.account,
            icon: <UserOutlined className="text-3xl" />,
        },
    ];
    return (
        <div className="flex flex-col flex-1 h-full">
            <div className="flex items-center p-1 border sticky ">
                <div className="flex flex-row items-baseline px-2">
                    <img src={logo} alt="Nook Logo" className="size-5" />
                    <p className="text-3xl font-bold pl-2 leading-snug  text-button-blue">
                        Nook
                    </p>
                </div>
                <div className="w-72 flex justify-around max-md:hidden pl-10 m-auto ">
                    {user &&
                        !pathname.includes(routeNames.onboard) &&
                        menuItems.map((item) => (
                            <Link
                                aria-label={item.label}
                                to={item.path}
                                key={item.label}
                                className={`flex-col flex items-center py-3 ${pathname === item.path ? 'text-black' : 'text-gray-400'}`}
                            >
                                {item.icon}
                            </Link>
                        ))}
                </div>
            </div>
            <div className="flex-1 flex h-full w-full relative">
                <Outlet />
                <div
                    className="absolute w-full flex justify-center"
                    style={{ bottom: '2em' }}
                >
                    <div className="flex justify-center w-8/12 max-md:hidden">
                        <MusicPlayerBar />
                    </div>
                </div>
            </div>
            <div className="rounded-md  border border-b-0  justify-around flex w-full md:hidden fixed bottom-0 bg-white">
                <div
                    className="absolute  flex justify-cente w-11/12"
                    style={{ top: '-4em' }}
                >
                    <MusicPlayerBar />
                </div>
                {user &&
                    !pathname.includes(routeNames.onboard) &&
                    menuItems.map((item) => (
                        <Link
                            aria-label={item.label}
                            to={item.path}
                            key={item.label}
                            // className={`flex-col flex items-center py-3 `}
                            className={`flex-col flex items-center py-3 ${pathname.includes(item.path) ? 'text-black' : 'text-gray-400'}`}
                        >
                            {item.icon}
                            <label
                                className={`text-sm ${pathname.includes(item.path) ? 'text-black' : 'text-gray-400'}`}
                            >
                                {item.label}
                            </label>
                        </Link>
                    ))}
            </div>
        </div>
    );
}

export default PageLayout;
