import React from 'react';
import {
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    Navigate,
} from 'react-router-dom';
import { routeNames } from './types.navigation.ts';
import LoginPage from '../pages/unauthenticated/LoginPage.tsx';
import NookPage from '../pages/authenticated/NookPage.tsx';
import AuthZone from './AuthZone.tsx';
import SignupPage from '../pages/unauthenticated/SignupPage.tsx';
import ExplorePage from '../pages/authenticated/ExplorePage.tsx';
import CategoryPage from '../pages/authenticated/CategoryPage.tsx';
import PlayPage from '../pages/authenticated/PlayPage.tsx';
import PageLayout from '../components/PageLayout.tsx';
import OnboardPage from '../pages/authenticated/OnboardPage.tsx';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<PageLayout />}>
            <Route index element={<Navigate to={routeNames.signup} />} />

            {/* Unauthenticated routes */}
            <Route path={routeNames.login} element={<LoginPage />} />
            <Route path={routeNames.signup} element={<SignupPage />} />

            {/* Authenticated routes */}
            <Route element={<AuthZone />}>
                <Route path={routeNames.onboard} element={<OnboardPage />} />

                <Route path={routeNames.nook} element={<NookPage />} />
                <Route path={routeNames.explore}>
                    <Route index element={<ExplorePage />} />
                    <Route path={routeNames.category}>
                        <Route path=":id" element={<CategoryPage />} />
                    </Route>
                </Route>
                <Route path={routeNames.play}>
                    <Route path=":id" element={<PlayPage />} />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to={routeNames.nook} />} />
        </Route>
    )
);

export default router;
