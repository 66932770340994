import {
    CaretRightOutlined,
    HeartOutlined,
    PauseOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Content } from '../../queries/content.query.ts';
import { capitalizeFirstLetter } from '../../utils/string.helpers.ts';
import { useAudio } from '../../contexts/AudioContext.tsx';

function PlayPage() {
    const navigate = useNavigate();
    const { isPlaying, pauseAudio, playAudio } = useAudio();
    const [searchParams] = useSearchParams();
    const content = JSON.parse(atob(searchParams.get('data'))) as Content;
    const onAudioClickHandler = () => {
        if (isPlaying) {
            pauseAudio();
        } else {
            playAudio(content);
        }
    };
    return (
        <div className="bg-gradient-to-r from-sky-100 via-sky-200 to-sky-400 bg-[length:400%_400%] animate-gradient bg-gradient-45 h-full w-full p-4">
            <div className="flex flex-row justify-between">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <HeartOutlined className="text-2xl" />
            </div>
            <div className="flex h-full items-center justify-center flex-col w-full">
                <h3>{content.title}</h3>
                <label className="font-normal">
                    {capitalizeFirstLetter(content.mediaCategory)}
                </label>
                <button
                    onClick={onAudioClickHandler}
                    className="bg-black rounded-full h-28 w-28 flex justify-center items-center"
                >
                    {isPlaying ? (
                        <PauseOutlined className="text-white text-7xl" />
                    ) : (
                        <CaretRightOutlined className="text-white text-7xl" />
                    )}
                </button>
            </div>
        </div>
    );
}

export default PlayPage;
