/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export enum routeNames {
    login = '/login',
    signup = '/signup',
    nook = '/nook',
    explore = '/explore',
    account = '/account',
    category = 'category',
    play = '/play',
    onboard = '/onboard',
}
