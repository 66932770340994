import React from 'react';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { useAudio } from '../contexts/AudioContext.tsx';

function MusicPlayerBar() {
    const { content, isPlaying, playAudio, pauseAudio } = useAudio();
    if (!content) {
        return null;
    }
    function onPlayHandler() {
        if (isPlaying) {
            pauseAudio();
        } else {
            playAudio(content);
        }
    }
    return (
        <div className="p-2 w-full rounded bg-gray-100 flex items-center justify-between md:p-4">
            <div className="flex flex-row items-center">
                <div className="rounded bg-gray-400 h-8 w-8 overflow-hidden mr-2 md:h-16 md:w-16 md:mr-4">
                    <img src={content.image} alt="" />
                </div>
                <h4 className="md:text-2xl md:font-semibold">
                    {content.title}
                </h4>
            </div>
            <div className="flex flex-row justify-center items-center">
                <button
                    type="button"
                    onClick={onPlayHandler}
                    className="flex flex-row justify-center items-center"
                >
                    {isPlaying ? (
                        <PauseCircleFilled className="text-3xl md:text-6xl" />
                    ) : (
                        <PlayCircleFilled className="text-3xl md:text-6xl" />
                    )}
                </button>
            </div>
        </div>
    );
}

export default MusicPlayerBar;
