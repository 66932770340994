import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase/config.firbase.ts';
import { collections } from './types.query.ts';

export type InterstResponse = {
    id: string;
    title: string;
};

async function getAllInterests(): Promise<InterstResponse[]> {
    // Reference the collection you want to fetch
    const querySnapshot = await getDocs(collection(db, collections.interests));

    // Process the data and set it to state
    const dataList = querySnapshot.docs.map((interst) => ({
        id: interst.id,
        ...interst.data(),
    }));
    return dataList as unknown as InterstResponse[];
}

export function useGetAllInterestsQurey() {
    const [interests, setInterests] = useState<InterstResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const handleQuery = async () => {
        setError(null);
        setLoading(true);
        try {
            const newCategories = await getAllInterests();
            setInterests(newCategories);
        } catch (e) {
            setError('Failed to get Data');
            console.error('Error fetching data: ', e);
        }
        setLoading(false);
    };
    useEffect(() => {
        handleQuery();
    }, []);

    return {
        interests,
        loading,
        error,
    };
}
