import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/common.tsx';
import { useGetAllInterestsQurey } from '../../queries/interests.query.ts';
import { setUserDataInterests } from '../../queries/users.query.ts';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';

type OnboardTemplate = {
    header: string;
    description: string;
    children: ReactNode;
};
function OnboardTemplate(props: PropsWithChildren<OnboardTemplate>) {
    const { description, header, children } = props;
    return (
        <div className="flex flex-1  flex-col justify-between md:justify-normal md:flex-none items-center ">
            {children}
            <div className=" w-full md:w-6/12 text-center ">
                <h1 className="pb-2">{header}</h1>
                <p>{description}</p>
            </div>
        </div>
    );
}

function Pill(props: {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}) {
    const { isSelected, onClick, title } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={`rounded-full w-full p-2 ${isSelected ? 'text-white bg-blue-400' : 'text-blue-400 bg-white'} border-blue-400 border font-bold text-lg text-left px-5`}
        >
            {title}
        </button>
    );
}

function InterestList() {
    const { interests } = useGetAllInterestsQurey();
    const { user } = useAuth();
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    async function onToggleInterestHandler(id: string) {
        if (user?.uid) {
            if (selectedInterests.includes(id)) {
                const newSelectedInterests = selectedInterests.filter(
                    (item) => item !== id
                );
                setSelectedInterests(newSelectedInterests);
                await setUserDataInterests(user?.uid, newSelectedInterests);
            } else {
                const newSelectedInterests = [...selectedInterests, id];
                setSelectedInterests(newSelectedInterests);
                await setUserDataInterests(user?.uid, newSelectedInterests);
            }
        }
    }

    return (
        <div className="flex flex-col w-full">
            {interests.map((item) => (
                <div className="pb-2">
                    <Pill
                        isSelected={selectedInterests.includes(item.id)}
                        title={item.title}
                        onClick={() => onToggleInterestHandler(item.id)}
                    />
                </div>
            ))}
        </div>
    );
}
const onboardingSlides: OnboardTemplate[] = [
    {
        header: 'Start your journey',
        description:
            'Welcome to Nook, your peaceful space for motherhood. Designed to support and uplift you on this beautiful yet challenging journey.',
        children: (
            <div className="flex flex-1 md:flex-none justify-center items-center max-w-96 ">
                <img src="https://ddad.com.au/welcome_slide.png" alt="" />
            </div>
        ),
    },
    {
        header: 'What do you need support with?',
        description:
            'Select areas where you need emotional support. Nook will recommend content to meet your unique needs. ',
        children: (
            <div className=" w-full max-w-96 ">
                <InterestList />
            </div>
        ),
    },
    {
        header: 'Have a small gift',
        description: 'Enjoy 3 days of Premium access for free to explore Nook',
        children: (
            <div className="rounded-full min-h-72 w-72 overflow-hidden ">
                <img src="https://ddad.com.au/complete_page.png" alt="" />
            </div>
        ),
    },
];
function OnboardPage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const { user, completeOnboardHandler } = useAuth();
    const navigate = useNavigate();
    const onNextHandler = async () => {
        if (currentSlideIndex === onboardingSlides.length - 1) {
            if (user?.uid) {
                completeOnboardHandler();
                navigate(routeNames.nook);
            }
        } else {
            setCurrentSlideIndex(currentSlideIndex + 1);
        }
    };
    const ActionButtonText =
        currentSlideIndex === onboardingSlides.length - 1 ? 'Complete' : 'Next';
    return (
        <div className="p-4 w-full ">
            {currentSlideIndex !== 0 && (
                <button
                    type="button"
                    className="self-start max-md:hidden "
                    onClick={() => {
                        setCurrentSlideIndex(currentSlideIndex - 1);
                    }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </button>
            )}
            <div className="items-center flex flex-col md:justify-center w-full h-full">
                {currentSlideIndex !== 0 && (
                    <button
                        type="button"
                        className="self-start md:hidden "
                        onClick={() => {
                            setCurrentSlideIndex(currentSlideIndex - 1);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="text-2xl"
                        />
                    </button>
                )}
                <OnboardTemplate {...onboardingSlides[currentSlideIndex]} />
                <div className="mt-2 text-center w-full md:w-6/12 items-center">
                    <div className="flex justify-center mb-4 ">
                        {onboardingSlides.map((_, index) => (
                            <span
                                key={_.header}
                                // eslint-disable-next-line prettier/prettier
                                className={`w-3 h-3 mx-1 rounded-full 
                                ${index <= currentSlideIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                            />
                        ))}
                    </div>
                    <Button onClick={onNextHandler}>{ActionButtonText}</Button>
                </div>
            </div>
        </div>
    );
}

export default OnboardPage;
