import React from 'react';
import { Link } from 'react-router-dom';
import useGetAllCategoriesQurey, {
    CategoryResponse,
} from '../../queries/categories.query.ts';
import { Page } from '../../components/common.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';

function CategoryButton({ item }: { item: CategoryResponse }) {
    const url = `${routeNames.explore}/${routeNames.category}/${item.id}?data=${encodeURIComponent(btoa(JSON.stringify(item)))}`;
    return (
        <Link
            to={url}
            className="w-full py-4 px-3 rounded-lg text-left overflow-hidden relative flex items-center "
            style={{ backgroundColor: item.bgColor, textDecoration: 'none' }}
        >
            <img
                src={item.image}
                alt=""
                className="absolute w-20"
                style={{
                    top: -20,
                    right: 0,
                }}
            />
            <label
                className="text-lg "
                style={{
                    color: item.fontColor,
                }}
            >
                {item.title}
            </label>
        </Link>
    );
}

function ExplorePage() {
    const { categories, loading, error } = useGetAllCategoriesQurey();
    return (
        <Page>
            <h1>Explore</h1>
            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">error</p>}
            {categories &&
                categories.map((item) => (
                    <CategoryButton key={item.id} item={item} />
                ))}
        </Page>
    );
}

export default ExplorePage;
