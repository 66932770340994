import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './common.tsx';
import { useAuth } from '../contexts/AuthContext.tsx';
import { routeNames } from '../navigation/types.navigation.ts';

function GoogleButton({ children }: PropsWithChildren) {
    const { withGoogle } = useAuth();
    const navigate = useNavigate();
    const handleSignUp = async () => {
        try {
            await withGoogle();
            navigate(routeNames.nook);
        } catch (err) {
            console.log('Failed to sign up');
        }
    };
    return <Button onClick={handleSignUp}>{children}</Button>;
}

export default GoogleButton;
